<template>
    <div>
        <div class="page-title">搭配列表</div>

        <div class="page-header scheme-tools">
            <div class="ph-left">
                <button @click="changeTJ('all')" class="btn" :class="{active: tj === 'all'}">全部</button>
                <button @click="changeTJ('no')" class="btn" :class="{active: tj === 'no'}">未添加</button>
                <button @click="changeTJ('yes')" class="btn" :class="{active: tj === 'yes'}">已添加</button>
            </div>
            <div class="ph-right">
                <!--                <button class="btn">上架</button>-->
            </div>
        </div>

        <div v-if="listLoading" class="loading-block">
            <loader/>
        </div>
        <div v-else-if="error">
            <a-alert type="error" :message="error" banner/>
        </div>
        <div v-else class="goods-list-container">

            <div class="goods-list">

                <template v-for="(item,ik) in list">

                    <div :key="ik" class="goods-item" @click="showQrcode(item)">
                        <div class="goods-thumb">
                            <div v-if="item.status" class="scheme-in-stock"></div>
                            <img :src="item.thumb" alt="">
                            <div @click="showVide(item)"
                                 class="scheme-video-btn"
                                 :class="{on:item.video_id && item.video_account}"
                            ></div>

                            <div class="scheme-id">W{{ item.id }}</div>
                        </div>
                        <div class="goods-content">
                            <div class="goods-name">{{ item.title }}</div>
                        </div>
                    </div>

                </template>

                <div class="goods-item"></div>
                <div class="goods-item"></div>
                <div class="goods-item"></div>
                <div class="goods-item"></div>
                <div class="goods-item"></div>
            </div>

            <div>
                <a-pagination
                        class="st-pagination"
                        v-if="pager.total > 0 "
                        :current="pager.current"
                        :total="pager.total"
                        :page-size="pager.size"
                        :show-total="(total, range) => `${range[0]}-${range[1]},共${total}条数据`"
                        @change="gotoPage"
                />
            </div>
        </div>

        <div v-if="showTipsModal" class="tips-modal">
            <div @click="showTipsModal = false" class="tips-close"></div>
            <img src="/assets/images/tips-img.png" alt="">
        </div>

        <at-modal title="搭配视频" v-model="showVideoModal" @ok="confirmVideo">
            <div class="tips">
                <div @click="showTipsModal = true " class="tips-btn"></div>
            </div>

            <div class="form-title">视频号ID</div>
            <div class="form-content">
                <at-input v-model="selectedItem.video_account" class="full-with"/>
            </div>
            <div class="form-title">视频ID</div>
            <div class="form-content">
                <at-input v-model="selectedItem.video_id" class="full-with"/>
            </div>

        </at-modal>

        <scheme-qrcode @deleted="getList" v-model="showQrcodeModal" :scheme-id="detailSchemeId" type="wechat"></scheme-qrcode>

    </div>
</template>

<script>

import Loader from "../../../components/Loader";
import api from "../../../repo/api";
import AtModal from "../../../components/AtModal";
import AtInput from "../../../components/AtInput";
import SchemeQrcode from "@/components/SchemeQrcode.vue";

export default {
    name: "Goods",
    components: {SchemeQrcode, AtInput, AtModal, Loader},
    data() {
        return {
            detailSchemeId: 0,
            showQrcodeModal: false,
            tj: 'all',
            listLoading: true,
            showVideoModal: false,
            showTipsModal: false,
            defaultAccount: "",
            error: '',
            query: {
                page: 1
            },
            pager: {
                defaultCurrent: 1,
                current: 1,
                defaultPageSize: 10,
                total: 1,
                pageSize: 15,
            },
            list: [],
            selectedItem: {},
        }
    },
    mounted() {
        this.getFirstPage()
    },
    methods: {
        changeTJ(tj) {
            this.tj = tj
            this.getFirstPage()
        },
        confirmVideo() {
            let params = {
                id: this.selectedItem.id,
                video_id: this.selectedItem.video_id,
                video_account: this.selectedItem.video_account,
            }

            this.$loading.show()

            api.post('/ast-app/scheme-video', params, (data) => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {
                    this.showVideoModal = false
                    this.getList()
                }

            })
        },
        showVide(item) {
            this.selectedItem = JSON.parse(JSON.stringify(item))

            if (!this.selectedItem.video_account) {
                this.selectedItem.video_account = this.defaultAccount
            }

            this.showVideoModal = true
        },
        gotoPage(page) {
            this.query.page = page
            this.getList()
        },
        getFirstPage() {
            this.gotoPage(1)
        },
        showQrcode(item) {
            this.detailSchemeId = item.id
            this.showQrcodeModal = true
        },
        getList() {
            this.listLoading = true

            this.query.tj = this.tj

            api.get('/ast-app/shop-schemes', this.query, (data) => {

                this.listLoading = false

                if (data.code !== 0) {
                    this.error = data.msg
                    return
                }

                this.list = data.data.data
                this.pager.current = data.data.current_page
                this.pager.total = data.data.total
                this.pager.size = data.data.per_page
                this.defaultAccount = data.data.default_account

            })
        }
    }
}
</script>

<style lang="less" scoped>

.goods-list {
    display: flex;
    flex-wrap: wrap;
}

.goods-name {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    margin-bottom: 11px;
}

.goods-item {
    width: 232px;
    margin-right: 24px;
    margin-bottom: 32px;
    cursor: pointer;
}

.goods-attr {
    display: flex;
    justify-content: space-between;
}

.goods-thumb {
    width: 232px;
    height: 232px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F5F5;
    padding: 16px;
    margin-bottom: 16px;
    position: relative;


    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.st-pagination {
    margin-bottom: 32px;
}

.goods-price {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    line-height: 12px;
}

.goods-stock {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 17px;
    font-size: 12px;
}

.scheme-tools {
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    .btn {
        border: 1px solid #EEEEEE;
        background-color: white;
        color: #999999;
        line-height: 20px;
        margin-right: 16px;
    }

    .active {
        background-color: black;
        color: white;
    }
}

.scheme-in-stock {
    width: 56px;
    height: 56px;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url("/assets/icons/scheme-instock.png");
    background-size: cover;
}

.scheme-video-btn {
    width: 24px;
    height: 24px;

    position: absolute;
    right: 12px;
    top: 12px;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;

    background-image: url("/assets/icons/scheme-video-off.png");
    background-size: contain;
    cursor: pointer;

    &.on {
        background-image: url("/assets/icons/scheme-video-on.png");
    }
}

.tips {
    position: absolute;
    right: 48px;
    top: 19px;
    height: 16px;
    width: 28px;
    border-right: 1px solid #EEEEEE;
}

.tips-btn {
    width: 16px;
    height: 16px;
    background-image: url("/assets/icons/tips-btn.png");
    background-size: contain;
    cursor: pointer;
}

.form-content {
    margin-bottom: 24px;
}

.form-title {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    margin-bottom: 16px;
}

.tips-modal {
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 80%;
        max-height: 80%;
    }
}

.tips-close {
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 40px;
    right: 40px;
    border-radius: 50%;
    cursor: pointer;
    background-image: url("/assets/icons/tips-modal-close.png");
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
}

.scheme-id {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 1px 10px;
    background-color: #333333;
    color: white;
}
</style>